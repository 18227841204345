import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Config } from '../config'


const Co = () => {
	return (
		<L10nProvider>
			<Config />
		</L10nProvider>
	)
}

export default Co;
